import { Typography, TypographyProps } from "@mui/material";

const baseProps = {
  fontSize: { xs: 10, md: 12 },
};

const XsText = (props: TypographyProps) => {
  return <Typography {...baseProps} {...props} />;
};

export const XsTextMd = (props: TypographyProps) => {
  return <XsText fontWeight={500} {...props} />;
};

export default XsText;
