import { Conversation, Message } from "@twilio/conversations";
import {
  ConversationAttributeType,
  MessageAttributeEmbedType,
  MessageAttributeType,
} from "types/chat";
import { AgentChatMessage, BotMessageType } from "types/ai-agent";

export const getConversationAttributes = (
  conversation?: Conversation
): ConversationAttributeType => {
  if (
    conversation &&
    typeof conversation.attributes === "object" &&
    conversation.attributes !== null
  ) {
    return conversation.attributes as ConversationAttributeType;
  }
  return {};
};

export const getEmbedFromAttributes = (
  attributes: unknown
): MessageAttributeEmbedType | null => {
  try {
    const parsedAttributes =
      typeof attributes === "string" ? JSON.parse(attributes) : attributes;

    if (
      parsedAttributes &&
      typeof parsedAttributes === "object" &&
      "embed" in parsedAttributes
    ) {
      return (parsedAttributes as MessageAttributeType).embed || null;
    }

    return null; // Return null if no embed field exists
  } catch (error) {
    console.error("Error parsing or accessing embed from attributes:", error);
    return null;
  }
};

export const getMessageAttributes = (
  message?: Message
): MessageAttributeType => {
  try {
    if (typeof message?.attributes === "object") {
      const { embed, hidden } = message.attributes as any;
      return {
        embed,
        hidden: Array.isArray(hidden) ? hidden : undefined,
      };
    }
    return {};
  } catch (error) {
    console.error("Error parsing message attributes:", error);
  }

  return {};
};

export const CHAT_EMBED_OBJECT = {
  quotation: "Quotation",
  inquiry: "Inquiry",
  supplier: "Supplier",
};

export const isLoginAgentMessage = (content: string) => {
  try {
    const parsedContent = JSON.parse(content);
    return parsedContent.type === "login";
  } catch (error) {
    return false;
  }
};

export const isHideMessage = (message: AgentChatMessage) => {
  const messageType = message.message_type;
  try {
    if (messageType !== "assistant_message" && messageType !== "user_message")
      return true;
    const parsedContent = JSON.parse(message?.content || "");
    return (
      messageType === "user_message" &&
      ["heartbeat", "login"].indexOf(parsedContent.type) > -1
    );
  } catch (error) {
    return false;
  }
};

const EXCEPT_IMGS = [
  "https://business.freshdi.com/img/Freshdi_pictorial_logo.png",
];

export const hideMessageFavicon = (url: string) => {
  return EXCEPT_IMGS.indexOf(url) >= 0;
};

export const underlineLinkExtension = () => {
  return [
    {
      type: "output",
      regex: /<a href="(.*?)"(.*?)>(.*?)<\/a>/g,
      replace:
        '<a href="$1"$2 style="text-decoration: underline;" target="_blank" rel="noopener noreferrer">$3</a>',
    },
  ];
};

export function fixOrderedListStructure(html: string) {
  let firstOlFound = false;

  html = html
    .replace(/<\/?ol.*?>/g, (match) => {
      if (!firstOlFound) {
        firstOlFound = true;
        return match;
      }
      return "";
    })
    .replace(/<\/li>\s*<ul>/g, "</li><ul>");
  html = html.replace(/<\/ul>(?![\s\S]*<\/ul>)/, "</ul></ol>");

  return html;
}

export const newBotMessage = (): BotMessageType => ({
  id: crypto.randomUUID(),
  date: new Date().toISOString(),
  message_type: "assistant_message",
  content: "",
  generating: true,
});
