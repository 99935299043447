import { SVGProps } from "react";

export function SendIcon({
  width = 20,
  height = 20,
  viewBox = "0 0 20 20",
  stroke = "#667085",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg {...{ width, height, viewBox }} fill="none" xmlns={xmlns} {...props}>
      <g clipPath="url(#clip0_12582_177719)">
        <path
          d="M18.0764 2.34619L9.25316 11.1694M18.0764 2.34619L12.4616 18.3884L9.25316 11.1694M18.0764 2.34619L2.03418 7.96096L9.25316 11.1694"
          stroke={stroke}
          strokeWidth="1.60422"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_12582_177719">
          <rect
            width="19.2506"
            height="19.2506"
            fill="white"
            transform="translate(0.429688 0.741943)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
export function MajestSendIcon({
  fill = "#008934",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.95388 1.87121C2.07827 1.76316 2.2319 1.6944 2.39536 1.67364C2.55881 1.65287 2.72476 1.68103 2.87221 1.75455L17.8722 9.25455C18.0109 9.32366 18.1275 9.43007 18.2091 9.56182C18.2906 9.69357 18.3338 9.84544 18.3338 10.0004C18.3338 10.1553 18.2906 10.3072 18.2091 10.4389C18.1275 10.5707 18.0109 10.6771 17.8722 10.7462L2.87221 18.2462C2.72477 18.32 2.55875 18.3484 2.39517 18.3278C2.23159 18.3072 2.07779 18.2385 1.95325 18.1305C1.82871 18.0224 1.73901 17.8799 1.69551 17.7208C1.65201 17.5618 1.65666 17.3934 1.70888 17.237L3.84388 10.8337H8.33305C8.55406 10.8337 8.76602 10.7459 8.9223 10.5896C9.07858 10.4334 9.16638 10.2214 9.16638 10.0004C9.16638 9.77937 9.07858 9.56741 8.9223 9.41113C8.76602 9.25485 8.55406 9.16705 8.33305 9.16705H3.84388L1.70805 2.76371C1.6561 2.60739 1.65165 2.43918 1.69526 2.28033C1.73886 2.12147 1.8294 1.97911 1.95388 1.87121Z"
        fill={fill}
      />
    </svg>
  );
}
interface RedCloseIconProps extends SVGProps<SVGSVGElement> {
  colorIcon?: string;
}

export function RedCloseIcon({
  xmlns = "http://www.w3.org/2000/svg",
  colorIcon,
  ...props
}: RedCloseIconProps) {
  return (
    <svg
      width={18}
      height={18}
      viewBox={"0 0 18 18"}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M13.5 4.5L4.5 13.5M4.5 4.5L13.5 13.5"
        stroke={colorIcon}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}