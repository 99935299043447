import { APP_IMGS } from "assets/images";
import { Link } from "components/link";
import { PATHS } from "constants/routes";
import React, { ImgHTMLAttributes } from "react";
import { onOpenChat } from "services/redux/actions/chat";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getOpenChat } from "services/redux/selectors/chat";
import useBreakpoints from "hooks/useBreakpoint";

const AiAgentButton = (props: ImgHTMLAttributes<HTMLImageElement>) => {
  const dispatch = useDispatch();
  const isOpenChat = useSelector(getOpenChat);
  const { isMobile } = useBreakpoints();

  const handleClickOpenChat = () => {
    if(isMobile) {
      window.location.href = PATHS.aiAgent;
    }
    else {
      dispatch(onOpenChat(true))
    }
  }

  return (
    // <Link to={PATHS.aiAgent}>
    <Link onClick={() => handleClickOpenChat()} style={{cursor: 'pointer'}}>
      <img
        src={isOpenChat? APP_IMGS.common.desktop["aiAgent"]  : '/images/common/ai_agent_unActive.svg'}
        alt="ico"
        width={32}
        height={32}
        style={{
          verticalAlign: "middle",
        }}
        {...props}
        loading="lazy"
      />
    </Link>
  );
};

export default AiAgentButton;
