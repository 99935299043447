import {
  ADVANCE_CROWN_ICON,
  APP_ICONS,
  PRO_CROWN_ICON,
  STARTED_CROWN_ICON,
} from "assets/icons";

const LISTING_SORTERS = [
  { label: "Liên quan nhất", value: "relatest" },
  { label: "Theo giá tiền (Từ cao đến thấp)", value: "price down" },
  { label: "Theo giá tiền (Từ thấp đến cao)", value: "price up" },
  { label: "Mới nhất", value: "newest" },
];

const COUNTRY_FLAG = (code: string) => `https://flagcdn.com/w20/${code}.png`;

export const COUNTRY_CODES = {
  en: "en",
  vi: "vi",
  zh: "zh",
};

const COUNTRIES = [
  {
    value: COUNTRY_CODES.en,
    label: "English",
    matchCode: "gb",
    src: COUNTRY_FLAG("gb"),
  },
  {
    value: COUNTRY_CODES.vi,
    label: "Việt Nam",
    matchCode: "vn",
    src: COUNTRY_FLAG("vn"),
  },
  {
    value: COUNTRY_CODES.zh,
    label: "中国人",
    matchCode: "zh",
    src: COUNTRY_FLAG("cn"),
  },
];

const GENDERS = [
  { value: "male", label: "Common.male" },
  { value: "female", label: "Common.female" },
  { value: "other", label: "Common.other" },
];

export const PAYMENT_TERMS = [
  "T/T or L/C",
  "T/T",
  "L/C",
  "D/P",
  "W/U",
  "Money Gram",
  "PayPal",
  "Other",
];

export const TRADE_TERMS = [
  "FOB",
  "EXW",
  "FCA",
  "FAS",
  "CFR",
  "CIF",
  "CPT",
  "CIP",
  "DAT",
  "DDP",
  "DAP",
];

export const PLAN_NAMES = {
  free: "Free",
  trial: "Trial",
  pro: "Pro",
  advance: "Advance",
  starter: "Starter",
};

export const VERIFICATION_LEVELS = {
  level1: { title: "Level 1", daysToQuote: 0 },
  level2: { title: "Level 2", daysToQuote: 0 },
  level3: { title: "Level 3", daysToQuote: 0 },
};

export const SUPPLIER_PLANS = [
  {
    label: PLAN_NAMES.pro,
    icon: PRO_CROWN_ICON,
    planTextColor: "#FDB022",
    chipClass: "yellow-label",
    label2: "Gold",
    label3: "Premium",
    planIcon: APP_ICONS.planPremium,
  },
  {
    label: PLAN_NAMES.advance,
    icon: ADVANCE_CROWN_ICON,
    planTextColor: "#293056",
    chipClass: "gray-label",
    label2: "Silver",
    label3: "Superior",
    planIcon: APP_ICONS.planSuperior,
  },
  {
    label: PLAN_NAMES.starter,
    icon: STARTED_CROWN_ICON,
    planTextColor: "#9C2A10",
    chipClass: "brown-label",
    label2: "Bronze",
    label3: "Advance",
    planIcon: APP_ICONS.planAdvance,
  },
  { label: PLAN_NAMES.free, planTextColor: "#9C2A10", label3: "Free" },
  { label: PLAN_NAMES.trial, planTextColor: "#9C2A10", label3: "Trial" },
];

export const QUOTE_MSG_STATUS = {
  delivered: "Delivered",
  readByBuyer: "Read",
};

export const DEFAULT_WHATSAPP = "http://wa.me/+19293921846";

export const PAYMENT_STATUS = {
  PAID: { label: "paid", labelType: "green-label" },
  CANCELLED: { label: "cancelled", labelType: "purple-label" },
  REFUNDED: { label: "refunded", labelType: "yellow-label" },
  UNPAID: { label: "unpaid", labelType: "red-label" },
  PAYMENT_PENDING: { label: "payment_pending", labelType: "gray-label" },
};

export const PLAN_STATUS = {
  active: "ACTIVE",
};

export const REPORT_TYPE = {
  rfq: "RFQ",
  buyer: "Buyer",
  supplier: "Supplier",
  product: "Product",
};

export const COMMON_EMAIL_DOMAINS = [
  "gmail.com",
  "yahoo.com",
  "outlook.com",
  "hotmail.com",
  "aol.com",
  "protonmail.com",
  "zoho.com",
  "mail.com",
  "yandex.com",
  "gmx.com",
  "icloud.com",
];

export const PRODUCT_STATUS = {
  pending: { label: "pending", value: "0" },
  onDisplay: { label: "on_display", value: "1" },
  rejected: { label: "rejected", value: "2" },
  offline: { label: "offline", value: "3" },
};

export const POSITIONS = [
  { label: "Founder/ Owner", value: "Founder/ Owner" },
  { label: "C-Level Manager", value: "C-Level Manager" },
  {
    label: "Sale/ Marketing/ Sourcing Manager",
    value: "Sale/ Marketing/ Sourcing Manager",
  },
  { label: "Staff", value: "Staff" },
  { label: "Other", value: "Other" },
];

export const SALUTATIONS = [
  { value: "Madam" },
  { value: "Master" },
  { value: "Miss" },
  { value: "Mr" },
  { value: "Mrs" },
  { value: "Ms" },
];

export const COMPLAINT_TYPES = [
  { label: "scammer", value: "Scammer" },
  { label: "spam", value: "Spam" },
  {
    label: "unable_to_contact",
    value: "Unable to contact",
  },
  {
    label: "fake_false_information",
    value: "Fake/False information",
  },
  { label: "other", value: "Other" },
];

export const INQUIRY_CONTEXT = [
  { label: "Find suppliers", value: "Find suppliers" },
  { label: "Find buyers", value: "Find buyers" },
  {
    label: "List my company",
    value: "List my company",
  },
  {
    label: "Get membership consultation",
    value: "Get membership consultation",
  },
  { label: "Freelancer/ Contractor", value: "Freelancer/ Contractor" },
  { label: "Report abuse", value: "Report abuse" },
];

export { LISTING_SORTERS, COUNTRIES, GENDERS };
