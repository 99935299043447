import dayjs from "dayjs";

export const FREE_PACKAGE = "Free";

export const isValidPlan = (plan: any): boolean => {
  if (!plan) return false;

  const now = dayjs();
  return (
    plan.is_valid &&
    plan.plan_name &&
    plan.plan_name !== FREE_PACKAGE &&
    dayjs(plan.end_date).isAfter(now)
  );
};

export const isPremiumBuyer = (is_featured: number) => is_featured === 1;

export const colorPremiumBuyer = (is_featured: number, is_verified: number) => {
  if(is_featured === 0 && is_verified === 0){
    return 'linear-gradient(270deg, #667085 0%, #98A2B3 99.03%)'
  }
  else if (is_featured === 1 || is_verified === 1) {
    return "linear-gradient(90deg, #C4944C 6.42%, #B68040 33%, #F7D14E 51%, #A86B35 77%, #FAC068 99.99%)"
  }
  else {
    return "linear-gradient(to left,#027B30 0%, #049F3F 100%)"
  }
}