import { getCountryFlag } from "helpers/address";
import { HTMLAttributes, ImgHTMLAttributes, useMemo } from "react";
import {
  LazyLoadImage,
  LazyLoadImageProps,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { styled } from "@mui/material";

export const Img = ({
  src,
  alt = "img",
  loading = "lazy",
  ...props
}: ImgHTMLAttributes<HTMLImageElement>) => (
  <img src={src} alt={alt} loading={loading} {...props} />
);

export const Imgchat = (props: LazyLoadImageProps) => (
  <StyledLazyLoadImage
    effect="blur"
    loading="lazy"
    alt={props.alt || "img"}
    {...props}
  />
);
export const FlagImg = ({
  country_code = "",
  ...rest
}: { country_code?: string } & HTMLAttributes<HTMLImageElement>) => {
  const imageUrl = useMemo(
    () => getCountryFlag(country_code).src,
    [country_code]
  );

  return <img src={imageUrl} alt="flag" {...rest} loading="lazy"/>;
};

const StyledLazyLoadImage = styled(LazyLoadImage)({
  display: "flex",
});