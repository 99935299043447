import { ButtonProps } from "@mui/material";
import useUser from "hooks/useUser";
import { useLoading } from "providers/loading";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { apiPurchaseContact } from "services/api/contact";
import { apiGetUserBalance } from "services/api/user";
import { updateUserContactQuote } from "services/redux/actions/user";
import GrayButton from "./GrayButton";
import { showNotification } from "helpers/notification";

const GetContactButton = (
  props: ButtonProps & {
    onBonusGetContact?: () => void;
    contactData?: any;
    apiPurchase?: any;
    isHiddenContact?: boolean;
  }
) => {
  const {
    onBonusGetContact,
    contactData,
    apiPurchase = apiPurchaseContact,
    isHiddenContact= false,
    ...rest
  } = props;
  const t = useIntl();
  const { userContactQuote } = useUser();
  const { setLoading } = useLoading();
  const dispatch = useDispatch();

  const onClickSendContact = async () => {
    if (userContactQuote > 0) {
      try {
        setLoading(true);
        await apiPurchase?.(contactData);
        onBonusGetContact?.();
        apiGetUserBalance().then((res) => {
          dispatch(
            updateUserContactQuote(res.data.message?.data?.contact_quota)
          );
        });
        showNotification(`${t.formatMessage({id:"buyer_contact_sent"})}`, true)
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  return (
    <GrayButton onClick={onClickSendContact} {...rest} id="contact-btn">
      {t.formatMessage({ id: "get_contact" })}

      {!isHiddenContact && <span className="chip w-quote">
        - 1 {t.formatMessage({ id: "contact" })} / {userContactQuote}{" "}
        {t.formatMessage({
          id: userContactQuote > 1 ? "contacts_lowercase" : "contact",
        })}
      </span>}
    </GrayButton>
  );
};

export default GetContactButton;
