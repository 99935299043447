import { styled } from "@mui/material";
import { Link as RouterLink, LinkProps } from "react-router-dom";

interface CustomLinkProps extends Omit<LinkProps, "to"> {
  to?: string;
  openNewTab?: boolean;
  hideUnderline?: boolean;
}

const Link = ({
  to,
  openNewTab,
  hideUnderline = false,
  ...props
}: CustomLinkProps) => {
  const isExternal = typeof to === "string" && to.startsWith("http");

  return isExternal || !to ? (
    <StyledAnchor
      href={to}
      target={openNewTab ? "_blank" : undefined}
      rel={openNewTab ? "noopener noreferrer" : undefined}
      hideUnderline={hideUnderline}
      {...props}
    />
  ) : (
    <StyledRouterLink to={to} hideUnderline={hideUnderline} {...props} />
  );
};

const linkStyles = ({ hideUnderline }: { hideUnderline: boolean }) => ({
  textDecoration: "none",
  color: "inherit",
  "&:hover": {
    textDecoration: hideUnderline ? "none" : "underline",
  },
});

const StyledRouterLink = styled(RouterLink, {
  shouldForwardProp: (prop) => prop !== "hideUnderline",
})(linkStyles);

const StyledAnchor = styled("a", {
  shouldForwardProp: (prop) => prop !== "hideUnderline",
})(linkStyles);

export default Link;
