export const AI_PROCESSING_IMG = require("./ai_processing.gif");
export const QUOTE_LEFT_CONTACT_BLUR_IMG = require("./quote_left_to_contact.png");
export const CONTACT_INFO_BLUR_IMG = require("./blur/contact_info.png");

type BannerPaths = {
  [key: string]: string;
};

type BannersConfig = {
  desktop: BannerPaths;
  mobile: BannerPaths;
};

export const APP_IMGS: Record<"zh" | "vi" | "en" | "common", BannersConfig> = {
  zh: {
    desktop: {
      bannerRegister: "/images/zh/register_banner_desktop.webp",
      bannerBuyPlan: "/images/zh/buy_plan_banner_desktop.webp",
    },
    mobile: {
      bannerRegister: "/images/zh/register_banner_mobile.webp",
      bannerBuyPlan: "/images/zh/buy_plan_banner_mobile.webp",
    },
  },
  vi: {
    desktop: {
      bannerRegister: "/images/vi/register_banner_desktop.webp",
      bannerBuyPlan: "/images/vi/buy_plan_banner_desktop.webp",
    },
    mobile: {
      bannerRegister: "/images/vi/register_banner_mobile.webp",
      bannerBuyPlan: "/images/vi/buy_plan_banner_mobile.webp",
    },
  },
  en: {
    desktop: {
      bannerRegister: "/images/en/register_banner_desktop.webp",
      bannerBuyPlan: "/images/en/buy_plan_banner_desktop.webp",
    },
    mobile: {
      bannerRegister: "/images/en/register_banner_mobile.webp",
      bannerBuyPlan: "/images/en/buy_plan_banner_mobile.webp",
    },
  },
  common: {
    desktop: {
      blurInquiry: "/images/common/blur_inquiry_desktop.png",
      blurSendQuotation: "/images/common/blur_send_quotation.webp",
      blurInquiryChat: "/images/common/blur_inquiry_chat.png",
      blurQuotationChat: "/images/common/blur_quotation_chat.png",
      blurMessageChat: "/images/common/blur_message_chat.png",
      animationFreshdiLogo: "/images/common/animation_freshdi_logo.gif",
      chloeAvatar: "/images/common/chloe_avatar.png",
      freshdiLoading: "/images/common/freshdi_loading.gif",
      aiAgent: "/images/common/ai_agent.gif",
      bannerFilterForBuyerLead: "/images/common/Left_banner_for_filter.gif",
      banner_buyer1: "/images/common/banner_buyer_footer_1.webp",
      banner_buyer2: "/images/common/banner_buyer_footer_2.webp",
      banner_buyer3: "/images/common/banner_buyer_footer_3.webp",
      banner_buyer4: "/images/common/banner_buyer_footer_4.webp",
      banner_buyer5: "/images/common/banner_buyer_footer_5.webp",
      banner_buyer6: "/images/common/banner_buyer_footer_6.webp",
      banner_buyer7: "/images/common/banner_buyer_footer_7.webp",
      banner_buyer8: "/images/common/banner_buyer_footer_8.webp",
      banner_buyer9: "/images/common/banner_buyer_footer_9.webp",
      banner_buyer10: "/images/common/banner_buyer_footer_10.webp",
      banner_buyer_header1: "/images/common/banner_buyer_header_1.webp",
      banner_buyer_header2: "/images/common/banner_buyer_header_2.webp",
      banner_buyer_header3: "/images/common/banner_buyer_header_3.webp",
      banner_buyer_header4: "/images/common/banner_buyer_header_4.webp",
      banner_buyer_header5: "/images/common/banner_buyer_header_5.webp",
      banner_buyer_header6: "/images/common/banner_buyer_header_6.webp",
      banner_buyer_header7: "/images/common/banner_buyer_header_7.webp",
      banner_buyer_header8: "/images/common/banner_buyer_header_8.webp",
      banner_buyer_header9: "/images/common/banner_buyer_header_9.webp",
      banner_buyer_header10: "/images/common/banner_buyer_header_10.webp",
      banner_right_detail: "/images/common/Right_banner_detail.gif"
    },
    mobile: {
      blurInquiry: "/images/common/blur_inquiry_mobile.png",
      blurSendQuotation: "/images/common/blur_send_quotation.webp",
      banner_buyer1: "/images/common/banner_buyer_footer_mb_1.webp",
      banner_buyer2: "/images/common/banner_buyer_footer_mb_2.webp",
      banner_buyer3: "/images/common/banner_buyer_footer_mb_3.webp",
      banner_buyer4: "/images/common/banner_buyer_footer_mb_4.webp",
      banner_buyer5: "/images/common/banner_buyer_footer_mb_5.webp",
      banner_buyer6: "/images/common/banner_buyer_footer_mb_6.webp",
      banner_buyer7: "/images/common/banner_buyer_footer_mb_7.webp",
      banner_buyer8: "/images/common/banner_buyer_footer_mb_8.webp",
      banner_buyer9: "/images/common/banner_buyer_footer_mb_9.webp",
      banner_buyer10: "/images/common/banner_buyer_footer_10.webp",
      banner_buyer_header1: "/images/common/banner_buyer_header_mb_1.webp",
      banner_buyer_header2: "/images/common/banner_buyer_header_mb_2.webp",
      banner_buyer_header3: "/images/common/banner_buyer_header_mb_3.webp",
      banner_buyer_header4: "/images/common/banner_buyer_header_mb_4.webp",
      banner_buyer_header5: "/images/common/banner_buyer_header_mb_5.webp",
      banner_buyer_header6: "/images/common/banner_buyer_header_mb_6.webp",
      banner_buyer_header7: "/images/common/banner_buyer_header_mb_7.webp",
      banner_buyer_header8: "/images/common/banner_buyer_header_mb_8.webp",
      banner_buyer_header9: "/images/common/banner_buyer_header_mb_9.webp",
      banner_buyer_header10: "/images/common/banner_buyer_header_mb_10.webp",
      banner_right_detail: "/images/common/Right_banner_detail.gif"
    },
  },
};
