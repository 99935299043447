import { styled, Typography, TypographyProps } from "@mui/material";
import { COLORS } from "constants/layout";

const ResultText = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: COLORS.gray[600],
  fontSize: 14,
  "& .qlt": {
    fontWeight: 500,
    color: COLORS.red[500],
    fontSize: 16,
  },
  "& .search": {
    // Add your styles here
  },
  "@media (max-width: 768px)": {
    fontSize: 12,
    ".qlt": {
      fontSize: 14
    },
  },
}));

export default ResultText;
