import { Box, BoxProps, Stack, styled } from "@mui/material";

import {
  Attachment,
  DotStatus,
  Row,
  SendMessageButton,
  SmText,
  SmTextMd,
  StyledATag,
} from "components";
import { COLORS } from "constants/layout";
import { DIRECT_PATHS } from "constants/routes";
import { formatImg } from "helpers/format";
import { formatCurrencyWithUnit } from "helpers/string";
import { useTranslation } from "hooks/useTranslation";
import { QuotationType } from "types/quotaion";

const Quotation = ({
  quotation,
  hideActions,
  ...rest
}: {
  quotation: QuotationType;
  hideActions?: boolean;
} & BoxProps) => {
  const t = useTranslation();
  const { unit, unit_price, details } = quotation;

  const Component = () => (
    <StyledQuotation
      className="quotation-card"
      p={2}
      boxShadow="0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)"
      {...rest}
    >
      <Row alignItems="flex-start" spacing={1}>
        <img
          className="seller_img"
          src={formatImg(quotation.farm_avatar, 2)}
          alt="img"
          loading="lazy"
        />
        <Box flex={1}>
          <SmTextMd className="seller_name">
            {quotation?.user_full_name}
          </SmTextMd>
          <SmText className="seller_desc">{quotation?.farm_name}</SmText>
          <Stack spacing={0.5} mt={1}>
            {unit_price && (
              <SmText color={COLORS.gray[500]} className="infor-text">
                {t("unit_price")}:{" "}
                <span>
                  {`${formatCurrencyWithUnit(unit_price, "USD", "end")} /${
                    unit?.label || ""
                  }`}
                </span>
              </SmText>
            )}
            {details && (
              <SmText
                color={COLORS.gray[500]}
                className="infor-text cutoff-text twoline"
              >
                {t("description")}: <span>{details}</span>
              </SmText>
            )}

            {/* <Typography className="product_name">{quotation?.title}</Typography> */}
            {/* // <HtmlViewer
          //   content={quotation.details}
          //   fontSize={12}
          //   color="var(--gray)"
          // /> */}

            <Stack gap={1} direction="row" flexWrap="wrap">
              {quotation?.media?.map((media, i) => (
                <Attachment attachment={media.url} key={i} />
              ))}
            </Stack>
          </Stack>

          {!hideActions && (
            <Row justifyContent="flex-end">
              <DotStatus status={{ value: "0", label: "delivered" }} />
              <SendMessageButton identity={quotation.identity} hideIcon />
            </Row>
          )}
        </Box>
      </Row>
    </StyledQuotation>
  );

  if (hideActions) return <Component />;

  return (
    <StyledATag href={DIRECT_PATHS.myQuotations(true)}>
      <Component />
    </StyledATag>
  );
};

const StyledQuotation = styled(Box)({
  borderRadius: 8,
  backgroundColor: "#fff",
  ".seller_img": {
    width: 32,
    height: 32,
    objectFit: "cover",
    borderRadius: "50%",
  },
  ".seller_name": {},
  ".seller_desc, .product_desc, .quantity_text": {
    color: "var(--gray-color)",
  },
  ".product_name": {
    marginTop: 16,
    fontWeight: 700,
  },
  ".quantity_text": {
    fontWeight: 700,
    marginTop: 4,
  },
  ".send-msg-btn": {
    lineHeight: 1.5,
  },
  ".infor-text": {
    span: {
      color: COLORS.gray[900],
    },
  },
});

export default Quotation;
