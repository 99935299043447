import { Dialog } from "@mui/material";
import { UserPlusIcon } from "assets/icons";
import TextButton from "./TextButton";
// import ContactUnlockPopup from "components/popup/ContactUnlockPopup";
import { useTranslation } from "hooks/useTranslation";
import { useState } from "react";
import { ChatUserDetailType } from "types/chat";
import { useLoading } from "providers/loading";
import { apiPurchaseContact } from "services/api/contact";
import { showNotification } from "helpers/notification";
import { apiGetUserBalance } from "services/api/user";
import { useDispatch } from "react-redux";
import { updateUserContactQuote } from "services/redux/actions/user";
import useUser from "hooks/useUser";
import ContactUnlockPopup from "components/popup/ContactUnlockPopup";

type Props = {
  userDetail?: ChatUserDetailType;
  onReload?: () => void;
};

const UnlockChatButton = ({ userDetail, onReload }: Props) => {
  const t = useTranslation();
  const [showDialog, setShowDialog] = useState(false);
  const { setLoading } = useLoading();
  const dispatch = useDispatch();
  const { userContactQuote } = useUser();

  const onCloseDialog = () => {
    setShowDialog(false);
  };

  const onClickGetContact = async () => {
    if (userContactQuote > 0) {
      try {
        setLoading(true);
        await apiPurchaseContact({ contact_id: userDetail?.name }).then((res) => {
          showNotification(`${t("buyer_contact_sent")}`, true)
        });
        await apiGetUserBalance().then((res) => {
          dispatch(
            updateUserContactQuote(res.data.message?.data?.contact_quota)
          );
        });
        onReload?.();
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
    else {
      setShowDialog(true);
    }
  };

  return (
    <>
      <TextButton
        onClick={onClickGetContact}
        sx={{
          flexDirection: "column",
          fontSize: 12,
          whiteSpace: "nowrap",
        }}
      >
        <UserPlusIcon />
        {t("unlock_chat")}
      </TextButton>

      {/* <Dialog
        open={showDialog}
        onClose={onCloseDialog}
        PaperProps={{ sx: { width: "100%", maxWidth: 473 } }}
      >
        <ContactUnlockPopup onClose={onCloseDialog} />
      </Dialog>  */}
      <Dialog
        open={showDialog}
        onClose={onCloseDialog}
        PaperProps={{ sx: { width: "100%", maxWidth: 473 } }}
      >
        <ContactUnlockPopup
          {...{ onReload }}
          onClose={onCloseDialog}
          contact_id={userDetail?.name}
          title="use_credits_unlock_chat_card"
        />
      </Dialog>
    </>
  );
};

export default UnlockChatButton;
