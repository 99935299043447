import {
  BIZ_DOMAIN,
  BUYER_CENTER_DOMAIN,
  FRESHDI_HOME_DOMAIN,
  LANDING_DOMAIN,
  SOURCING_DOMAIN,
  SUPPLIER_CENTER_DOMAIN,
} from "./schemas";

export const PATHS = {
  home: "/",
  overview: "/desk",
  overviewFull: BIZ_DOMAIN + "/desk",
  myQuotations: "/my-quotations",
  myQuotationsFull: BIZ_DOMAIN + "/my-quotations",
  myQuotaionDetail: "/my-quotations/:quotationid",
  quotaionDetail: "/quotations/:quotationid",
  socialRegister: "/social-register",
  oldSourcingRequests: "/sourcing-requests",
  sourcingRequests: "/buyleads",
  sourcingRequestsFull: SOURCING_DOMAIN + "/buyleads",
  login: "/login",
  category: "/category",
  requestDetail: "/request",
  recommendation: "/recommondation",
  buyerCenter: BUYER_CENTER_DOMAIN,
  supplierCenter: `${SUPPLIER_CENTER_DOMAIN}/freshdi.com-1`,
  buyerFAQs: `${BUYER_CENTER_DOMAIN}/faqs`,
  supplierFAQs: `${SUPPLIER_CENTER_DOMAIN}/faqs-1`,

  startSelling: `${BIZ_DOMAIN}/products`,
  buyers: "/buyers",
  buyersFull: SOURCING_DOMAIN + "/buyers",
  buyerCategory: "/buyer-category",
  suppliers: "/suppliers",
  analytics: `/analytics`,
  smartTrace: "/smart-trace",
  bizDomain: BIZ_DOMAIN,
  freshdiSchedule: "https://freshdi.fillout.com/schedule",
  intro: `${LANDING_DOMAIN}/vi/freshdi-tien-phong-trong-xuat-khau-nong-san`,
  smartTraceFull: `${LANDING_DOMAIN}/smart-traceability-solution`,
  freshdiPromotion: `${LANDING_DOMAIN}/resources/premium-package-offered-to-the-first-500-customers-who-register`,
  buyerPricing: LANDING_DOMAIN + "/membership/membership-for-buyer",
  introTrialPlan: LANDING_DOMAIN + "/trial-plan",
  complaintCenter: `${LANDING_DOMAIN}/agreement-on-use-of-complaint-center`,

  // Biz
  orders: "/orders",
  inquiries: "/inquiries",
  inquiriesFull: BIZ_DOMAIN + "/inquiries",
  products: "/products",
  productsFull: `${BIZ_DOMAIN}/products`,
  createProductsFull: BIZ_DOMAIN + "/products/create",
  setting: "/setting",
  settingFull: BIZ_DOMAIN + "/setting",
  contacts: "/contacts",
  messages: "/messages",
  supplierMembership: `/membership`,
  membershipFull: `${BIZ_DOMAIN}/membership`,

  buyerHome: FRESHDI_HOME_DOMAIN,
  contactUs: `${FRESHDI_HOME_DOMAIN}/contact-us`,
  loginFull: FRESHDI_HOME_DOMAIN + "/login",
  registerFull: FRESHDI_HOME_DOMAIN + "/register",
  registerSupplier: FRESHDI_HOME_DOMAIN + "/register?acc-type=Seller",
  postRfq: `${FRESHDI_HOME_DOMAIN}/quick-rfq`,
  policy: `${FRESHDI_HOME_DOMAIN}/policy`,
  pricing: `${FRESHDI_HOME_DOMAIN}/membership-pricing`,
  searchSuppliers: `${FRESHDI_HOME_DOMAIN}/search-supplier`,
  searchProducts: `${FRESHDI_HOME_DOMAIN}/search`,
  termCondition: `${FRESHDI_HOME_DOMAIN}/terms-of-use`,
  supplierPricing: `${FRESHDI_HOME_DOMAIN}/membership-pricing`,
  buyerWorkspace: `${FRESHDI_HOME_DOMAIN}/user/overview`,
  buyerProfile: `${FRESHDI_HOME_DOMAIN}/user/profile`,
  buyerMembership: `${FRESHDI_HOME_DOMAIN}/user/membership`,
  buyerRequestQuotation: `${FRESHDI_HOME_DOMAIN}/user/request-quotation`,
  buyerInquiries: `${FRESHDI_HOME_DOMAIN}/user/inquiries`,
  buyerReceivedQuotation: `${FRESHDI_HOME_DOMAIN}/user/received-quotation`,
  buyerProductsFull: FRESHDI_HOME_DOMAIN + "/products",
  buyerSuppliersFull: FRESHDI_HOME_DOMAIN + "/suppliers",
  supplierCategoryFull: FRESHDI_HOME_DOMAIN + "/supplier-category",
  categoryFull: FRESHDI_HOME_DOMAIN + "/category",
  paymentSupplier: FRESHDI_HOME_DOMAIN + "/supplier-premium-payment",
  disclaimers: `${FRESHDI_HOME_DOMAIN}/disclaimers`,
  resetPassword: FRESHDI_HOME_DOMAIN + "/reset-pw",
  buyerOverview: `${FRESHDI_HOME_DOMAIN}/user/overview`,
  trustStar: `/trust-star`,
  aiAgent: `${FRESHDI_HOME_DOMAIN}/ai-agent`,
  buyerSuppliersFullForBuyer: `${FRESHDI_HOME_DOMAIN}/supplier`,
  productDetail: `${FRESHDI_HOME_DOMAIN}/product`
};

export const VIEW_STANDARDS = {
  createProduct: {
    en: "https://supplier-center.freshdi.com/huong-dan/product-information-standards",
    vi: "https://supplier-center.freshdi.com/huong-dan/quy-chuan-ve-noi-dung-tao-san-pham",
    zh: "https://supplier-center.freshdi.com/huong-dan/product-information-standards",
  },
};

export const ROUTES = [
  {
    title: "Social Register",
    componentPath: "/business-domain/social-register",
    path: PATHS.socialRegister,
  },
];

export const BIZ_AUTHEN_ROUTES = [
  {
    componentPath: "/business-domain/dashboard",
    path: PATHS.overview,
  },
  {
    componentPath: "/business-domain/products",
    path: PATHS.products,
  },
  {
    componentPath: "/business-domain/product-detail",
    path: PATHS.products + "/:itemid",
  },
  {
    title: "Messages",
    componentPath: "/business-domain/messages",
    path: PATHS.messages,
  },
  {
    title: "My quotations",
    componentPath: "/business-domain/my-quotations",
    path: PATHS.myQuotations,
  },
  {
    componentPath: "/business-domain/inquiries",
    path: PATHS.inquiries,
  },
  {
    componentPath: "/business-domain/inquiry-detail",
    path: `${PATHS.inquiries}/:inquiryId`,
  },
  {
    title: "My quotation detail",
    componentPath: "/business-domain/my-quotations-detail",
    path: PATHS.myQuotaionDetail,
  },
  {
    title: "Contact",
    componentPath: "/business-domain/contacts",
    path: PATHS.contacts,
  },
  {
    title: "Setting",
    componentPath: "/business-domain/setting",
    path: PATHS.setting,
  },
  {
    title: "Membership",
    componentPath: "/business-domain/membership",
    path: PATHS.supplierMembership,
  },
  {
    title: "Analytics",
    componentPath: "/business-domain/analytics",
    path: PATHS.analytics,
  },
  {
    title: "Smart trace",
    componentPath: "/business-domain/smart-trace",
    path: PATHS.smartTrace,
  },
  {
    componentPath: "/business-domain/smart-trace-detail",
    path: PATHS.smartTrace + "/:postid",
  },
];

export const SOURCING_ROUTES = [
  {
    componentPath: "/sourcing-domain/rfq-market",
    path: PATHS.sourcingRequests,
  },
  {
    redirectTo: PATHS.sourcingRequests,
    path: "/",
  },
  {
    redirectTo: PATHS.sourcingRequests,
    path: PATHS.oldSourcingRequests,
  },
  {
    componentPath: "/sourcing-domain/rfq-detail",
    path: `${PATHS.requestDetail}/:rfqId`,
  },
  {
    componentPath: "/sourcing-domain/category",
    path: `${PATHS.sourcingRequests}/:countryId/:categoryId`,
  },
  {
    componentPath: "/sourcing-domain/category",
    path: `${PATHS.buyerCategory}/:countryId/:categoryId`,
  },
  {
    componentPath: "/sourcing-domain/category",
    path: `${PATHS.sourcingRequests}/:categoryId`,
  },
  {
    componentPath: "/sourcing-domain/category",
    path: `${PATHS.buyerCategory}/:categoryId`,
  },
  {
    componentPath: "/sourcing-domain/buyers",
    path: PATHS.buyers,
  },
  {
    componentPath: "/sourcing-domain/buyer-detail",
    path: "/buyer/:buyerId",
  },

  // Redirection entries
  {
    path: `${PATHS.oldSourcingRequests}/:countryId/:categoryId`,
    redirectTo: `${PATHS.sourcingRequests}/:countryId/:categoryId`,
  },
  {
    path: `${PATHS.oldSourcingRequests}/:categoryId`,
    redirectTo: `${PATHS.sourcingRequests}/:categoryId`,
  },
];

export const DIRECT_PATHS = {
  myQuotations(fullLink?: boolean) {
    return `${fullLink ? BIZ_DOMAIN : ""}${PATHS.myQuotations}`;
  },
  myQuotationDetail(id: string = "", fullLink?: boolean) {
    return `${fullLink ? BIZ_DOMAIN : ""}${PATHS.myQuotations}/${id}`;
  },
  quotationDetail(id: string) {
    return `/quotations/${id}`;
  },
  category(id: string) {
    return `${PATHS.categoryFull}/${id}`;
  },
  categorySupplier(id: string, search?: string) {
    return `${PATHS.supplierCategoryFull}/${id}${
      search ? `?${SEARCH_PARAMS.sKeyword}=${search}` : ""
    }`;
  },
  rfqDetail(id: string, fullLink: boolean = false) {
    return `${fullLink ? SOURCING_DOMAIN : ""}${PATHS.requestDetail}/${id}`;
  },
  itemDetail(id: string, fullLink?: boolean) {
    return `${fullLink ? BIZ_DOMAIN : ""}${PATHS.products}/${id}`;
  },
  categoryBuyer(
    id: string,
    fullLink?: boolean,
    country?: string,
    search?: string
  ) {
    return `${fullLink ? SOURCING_DOMAIN : ""}${PATHS.buyerCategory}${
      country ? `/${country}` : ""
    }/${id}${search ? `?${SEARCH_PARAMS.sKeyword}=${search}` : ""}`;
  },
  buyerDetail(id: string, fullLink?: boolean) {
    return `${fullLink ? SOURCING_DOMAIN : ""}/buyer/${id}`;
  },
  supplierDetail(id?: string) {
    return FRESHDI_HOME_DOMAIN + "/supplier/" + id;
  },
  supplierOverview() {
    return PATHS.overviewFull;
  },
  postDetail(id: string) {
    return `${PATHS.smartTrace}/${id}`;
  },
  homeProductDetail(id: string) {
    return `${FRESHDI_HOME_DOMAIN}/product/${id}`;
  },
  inquiryDetail(id: string = "", fullLink?: boolean) {
    return `${fullLink ? PATHS.inquiriesFull : PATHS.inquiries}/${id}`;
  },
  categoryRfq(category?: string, fullLink?: boolean, country?: string) {
    return `${fullLink ? SOURCING_DOMAIN : ""}${PATHS.sourcingRequests}${
      country ? `/${country}` : ""
    }${category ? `/${category}` : ""}`;
  },
  searchBuyingLeads(search?: string) {
    return `${PATHS.sourcingRequestsFull}${
      search ? `?${SEARCH_PARAMS.keyword}=${search}` : ""
    }`;
  },
  searchBuyer(search?: string, fullLink?: boolean) {
    return `${fullLink ? PATHS.buyersFull : PATHS.buyers}${
      search ? `?${SEARCH_PARAMS.keyword}=${search}` : ""
    }`;
  },
  searchSupplier(search?: string) {
    return `${PATHS.searchSuppliers}${
      search ? `?${SEARCH_PARAMS.keyword}=${search}` : ""
    }`;
  },
};

export const SEARCH_PARAMS = {
  directUrl: "direct-url",
  quoteId: "quote-id",
  category_id: "category_id",
  keyword: "keyword",
  sKeyword: "s_keyword",
  searchType: "search-type",
  tab: "tab",
  startDate: "srtdate",
  endDate: "enddate",
  is_verified: "is_verified",
  countries: "countries",
  date: "date",
  scrollTo: "sc-to",
  accountType: "acc-type",
  product: "product",
  openConv: "open-conv",
  utmSource: "utm_source",
  uid: "uid",
  mail: "ml",
  username: "un",
  page: "page",
  popup: "popup",
};

export const SEARCH_PARAM_VALUES = {
  scrollToPostRfq: "rfqf",
  accountTypeBiz: "Seller",
  popupReview: "review",
  popupComplaint: "report",
  tabReview: "review",
};

export const SUBDOMAINS = {
  sourcing: process.env.REACT_APP_SOURCING_SUBDOMAIN || "",
  business: process.env.REACT_APP_BIZ_SUBDOMAIN || "",
};
