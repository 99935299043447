// NotificationHandler.tsx
import { Snackbar, Alert } from "@mui/material";
import React, { useState } from "react";
import ReactDOM from "react-dom";

let showNotification: (message: string, isSuccess?: boolean) => void = () => {};

const NotificationHandler = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  showNotification = (msg: string, isSuccess?:boolean) => {
    setMessage(msg);
    setOpen(true);
    isSuccess && setIsSuccess(isSuccess? true : false);
  };

  return ReactDOM.createPortal(
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={() => setOpen(false)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <Alert onClose={() => setOpen(false)} severity={isSuccess ? "success" :"error"}>
        {message}
      </Alert>
    </Snackbar>,
    document.body
  );
};

export { showNotification };
export default NotificationHandler;
