import { Box, Stack, styled } from "@mui/material";
import { APP_IMGS } from "assets/images";
import {
  AcceptButton,
  ConfirmButton,
  Img,
  Row,
  TextButton,
  TextSemibold,
} from "components";
import { PATHS } from "constants/routes";
import useAuth from "hooks/useAuth";
import useBreakpoints from "hooks/useBreakpoint";
import { useLanguageContext } from "providers/mul-language";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { BlockType } from "types/common";
import InquiryUnlockPopup from "./InquiryUnlockPopup";
import { useNavigate, useParams } from "react-router-dom";

const Blocker = ({ is_blocked, required }: BlockType) => {
  const intl = useIntl();
  const { onDirectLogin, onDirectSignup } = useAuth();
  const { language } = useLanguageContext();

  const onClickBecome = () => {
    window.location.href = PATHS.supplierPricing;
  };

  const blockTitle = useMemo(() => {
    return required === "login"
      ? "sign_in_sign_up_to_continue"
      : required === "premium"
      ? "buyers_blocker_desc"
      : required === "verified"
      ? "verified_to_all_results"
      : "";
  }, [required, language]);

  const BlockContent = useMemo(() => {
    switch (required) {
      case "login":
        return (
          <Row justifyContent="center">
            <AcceptButton onClick={onDirectSignup}>
              {intl.formatMessage({ id: "JoinForFree" })}
            </AcceptButton>
            <TextButton onClick={onDirectLogin}>
              {intl.formatMessage({ id: "login" })}
            </TextButton>
          </Row>
        );
      case "premium":
        return (
          <ConfirmButton onClick={onClickBecome}>
            {intl.formatMessage({ id: "become_premium_membership" })}
          </ConfirmButton>
        );

      case "verified":
        return (
          <AcceptButton onClick={onClickBecome}>
            {intl.formatMessage({ id: "verify_to_continue" })}
          </AcceptButton>
        );

      default:
        return null;
    }
  }, [required, language]);

  if (!is_blocked) {
    return null;
  }

  return (
    <StyledBlocker spacing={2} alignItems="center">
      {/*<Img src={RED_WARNING_ICON} alt="icon" className="warning-icon" />*/}
      <TextSemibold>
        {blockTitle &&
          intl.formatMessage({
            id: blockTitle,
          })}
      </TextSemibold>

      {BlockContent}
    </StyledBlocker>
  );
};

const StyledBlocker = styled(Stack)({
  textAlign: "center",
  paddingBottom: 32,
  borderRadius: 16,
  width: "100%",
  ".warning-icon": {
    width: 54,
    margin: "0 auto",
  },
  button: {
    maxWidth: 248,
  },
});

export const InquiryDetailBlocker = ({
  onReload,
}: {
  onReload: () => void;
}) => {
  const { isMobile } = useBreakpoints();
  const { inquiryId } = useParams();
  const navigate = useNavigate();

  const onDirectInquiries = () => navigate(PATHS.inquiries);

  return (
    <Stack position="relative">
      <Img
        src={APP_IMGS.common[isMobile ? "mobile" : "desktop"].blurInquiry}
        width="100%"
      />

      {inquiryId && (
        <Box
          position="absolute"
          left="50%"
          top="35%"
          borderRadius={3}
          bgcolor="#fff"
          boxShadow="0px 4px 20px rgba(0, 0, 0, 0.1)"
          width="calc(100% - 32px)"
          maxWidth={450}
          sx={{ transform: "translate(-50%, -50%)" }}
        >
          <InquiryUnlockPopup
            inquiry_id={inquiryId}
            onClose={onDirectInquiries}
            {...{ onReload }}
          />
        </Box>
      )}
    </Stack>
  );
};

export default Blocker;
