import { Divider, Stack, styled } from "@mui/material";
import { TargetIcon, TelesaleIcon } from "assets/icons";
import { TextButton } from "components/button/index";
import AiAgentButton from "components/button/AiAgentButton";
import { Link } from "components/link";
import { COLORS } from "constants/layout";
import { PATHS } from "constants/routes";
import { useTranslation } from "hooks/useTranslation";
import React, { Fragment, memo } from "react";
import { getOpenChat } from "services/redux/selectors/chat";
 import { useSelector } from "react-redux";
 import useBreakpoints from "hooks/useBreakpoint";

type Props = {};

const FloatingActionBar = (props: Props) => {
  const t = useTranslation();
  const isOpenChat = useSelector(getOpenChat);
  const { isMobile } = useBreakpoints();
  const actions = [
    {
      icon: () => <AiAgentButton width={24} height={24} />,
      label: "Vivian",
      // href: PATHS.aiAgent,
    },
    { icon: TelesaleIcon, label: "help", href: PATHS.contactUs },
    { icon: TargetIcon, label: "rfq", href: PATHS.postRfq },
  ];
  return (
    <StyledBar spacing={0.5} right={isOpenChat && !isMobile? '28.5%' : 0}>
      {actions.map((action) => (
        <Fragment key={action.label}>
          <Link to={action.href}>
            <TextButton>
              <action.icon />
              {t(action.label)}
            </TextButton>
          </Link>
          <Divider />
        </Fragment>
      ))}
    </StyledBar>
  );
};

const StyledBar = styled(Stack)({
  zIndex: 10,
  padding: 8,
  position: "fixed",
  // right: 0,
  top: "50%",
  backgroundColor: "#fff",
  boxShadow:
    "0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1)",
  button: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minWidth: 24,
    fontSize: 10,
    fontWeight: 600,
  },
  hr: {
    borderColor: COLORS.gray[100],
  },
});

export default memo(FloatingActionBar);
