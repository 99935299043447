import { Box, Button, Stack, StackProps, styled } from "@mui/material";
import { BaseButton, LgTextMd, LoadingSkeleton } from "components";
import useGetData from "hooks/useGetData";
import { memo, useMemo, useState } from "react";
import { apiGetAllCategories } from "services/api/category";
import { CategoryType } from "types/category";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import useMediaBreakpoints from "hooks/useMediaBreakpoints";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

type ParamsType = {
  categoryId: string;
};

const Categories = (
  props: StackProps & {
    onClickCategory?: (cate: CategoryType) => void;
    tmpCategories?: CategoryType[];
    showTitle?: boolean;
    defaultCollapsed?: boolean;
  }
) => {
  const {
    onClickCategory,
    tmpCategories,
    showTitle = true,
    defaultCollapsed = true,
    ...rest
  } = props;
  const { categoryId } = useParams<ParamsType>();

  const { downTabletBreakpoint: isMobile } = useMediaBreakpoints();
  const [collapsed, setCollapsed] = useState(defaultCollapsed);
  const intl = useIntl();
  const { isLoading, data } = useGetData({
    promise: () => {
      if (tmpCategories) return Promise.resolve();
      return apiGetAllCategories();
    },
  });

  const categories = useMemo(() => tmpCategories || data || [], [data]);

  const onChangeCollapse = () => setCollapsed(!collapsed);

  return (
    <Stack spacing={2} {...rest} marginTop={{md: '12px !important'}}>
      {showTitle && (
        <LgTextMd>{intl.formatMessage({ id: "categories" })}</LgTextMd>
      )}
      <StyledCategories spacing={1} direction="row">
        <Box
          className="category_list"
          sx={{ maxHeight: collapsed ? (isMobile ? 74 : 36) : "auto" }}
          overflow={collapsed ? "hidden" : ""}
        >
          {isLoading && <LoadingSkeleton count={1} />}
          {!isLoading &&
            categories.map((category: CategoryType) => {
              let { name, category_name } = category;
              return (
                <BaseButton
                  key={name}
                  className={`category_box chip ${
                    categoryId === name ? "selected" : ""
                  }`}
                  onClick={() => onClickCategory?.(category)}
                >
                  {category_name}
                </BaseButton>
              );
            })}
        </Box>

        {!isLoading && (
          <Button
            className="collapse"
            startIcon={
              collapsed ? <MdKeyboardArrowDown /> : <MdKeyboardArrowUp />
            }
            onClick={onChangeCollapse}
          />
        )}
      </StyledCategories>
    </Stack>
  );
};

const StyledCategories = styled(Stack)({
  ".title": {
    color: "var(--gray-color)",
    whiteSpace: "nowrap",
  },
  ".category_list": {
    display: "flex",
    gap: 8,
    flexWrap: "wrap",
    ".category_box:hover": {
      backgroundColor: "var(--gray-200)",
    },
  },
  ".collapse": {
    border: "1px solid #D0D5DD",
    color: "#000",
    ".MuiButton-startIcon": {
      marginLeft: 0,
      marginRight: 0,
    },
    width: 40,
    padding: 6,
    height: "fit-content",
    lineHeight: 1.5,
    minWidth: "fit-content",
  },
});

export default memo(Categories);
