import { COMMON_EMAIL_DOMAINS } from "constants/data";
import { MAX_NOTI_CHAR_QLT } from "constants/layout";
import { VALID_EMAIL } from "constants/validate";

export const formatCurrency = (text: string | number) => {
  return String(text)
    ?.toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const formatNumber = (text?: string | number) => {
  if (!text) return `${text}` === "0" ? 0 : "";
  return String(text)
    ?.toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const formatCurrencyWithUnit = (
  text: string | number,
  unit = "$",
  position: "start" | "end" = "start"
) => {
  if (!text) return "";
  const formattedPrice = String(text)
    ?.toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  return position === "start"
    ? unit + "" + formattedPrice
    : formattedPrice + "" + unit;
};

export const removeHTMLTags = (str: string = "") => {
  return (str || "").replace(/<\/?[^>]+(>|$)/g, "");
};

export const limitCountNoti = (count: number = 0) => {
  return Number(count) > 0
    ? `${Math.min(count || 0, MAX_NOTI_CHAR_QLT)}${
        count > MAX_NOTI_CHAR_QLT ? "+" : ""
      }`
    : null;
};

export const getDataFromUniqueNameChat = (
  uniqueName: string | null | undefined,
  field: "buyerIdentity" | "supplierIdentity"
): string | undefined | null => {
  try {
    if (uniqueName) {
      const [buyerIdentity, supplierIdentity] = uniqueName.split("|");
      return field === "buyerIdentity" ? buyerIdentity : supplierIdentity;
    }
    return undefined;
  } catch (error) {
    return uniqueName;
  }
};

export const concatIdentity = (
  buyerIdentity: string,
  supplierIdentity: string
) => {
  return `${buyerIdentity}|${supplierIdentity}`;
};

export const isNotNull = (variable: any) => {
  return variable !== null && variable !== undefined;
};

export const isInCommonEmailDomains = (email: string) => {
  const emailDomain = email.split("@")[1];
  return COMMON_EMAIL_DOMAINS.includes(emailDomain);
};

export const isValidEmail = (email: string) => {
  const emailRegex = VALID_EMAIL;
  return emailRegex.test(email);
};

export const obfuscateContact = (value?: string, type?: "email" | "phone") => {
  if (!value) return "";
  if (type === "email") {
    let emailParts = value.split("@");
    return "*".repeat(emailParts[0].length) + "@" + (emailParts[1] || "");
  }

  if (type === "phone") {
    return (
      value.substring(0, value.length - 4) +
      "*".repeat(2) +
      value.substring(value.length - 2)
    );
  }
  return value;
};
export const extractLink = (text: string) => {
  const urlRegex = /(https?:\/\/[^\s]+)/;
  const match = text.match(urlRegex);
  return match ? match[0] : null;
};

export const extractLinks = (text: string) => {
  const urlRegex = /\[.*?\]\((https?:\/\/[^\s)]+)\)/g;
  const matches = [...text.matchAll(urlRegex)];
  return matches.map((match) => match[1]);
};
