import { Box, BoxProps, Stack, styled } from "@mui/material";
// import { useMemo } from "react";
// import { APP_IMGS } from "assets/images";
// import { useLanguageContext } from "providers/mul-language";
import { OpenTabLink, StyledATag, XlTextMd } from "./Text";
import { PATHS } from "constants/routes";
// import { addUtmParam, objectToQueryString } from "helpers/search";
// import useMediaBreakpoints from "hooks/useMediaBreakpoints";
// import { encodeBase64 } from "helpers/base64";
import { useIntl } from "react-intl";
import { YellowButton } from "./Button";
// import { preloadImages } from "helpers/preload";
import {
  BuyerBanners,
} from "components";

export const InviteRegisterBanner = (props: BoxProps) => {
  // const { language } = useLanguageContext();
  // const { isMobile } = useMediaBreakpoints();

  // const currentBanner = useMemo(() => {
  //   return APP_IMGS[language][isMobile ? "mobile" : "desktop"].bannerRegister;
  // }, [language, isMobile]);

  // useEffect(() => {
  //   preloadImages([banners]);
  // }, [banners]);

  return (
    <StyledATag>
      <StyledInviteRegisterBanner {...props}>
        {/* <img src={currentBanner} alt="img" /> */}
        <BuyerBanners positionBanner="header-not-login"/>
      </StyledInviteRegisterBanner>
    </StyledATag>
  );
};

export const SuggestBuyPlanBanner = (props: BoxProps) => {
  // const { language } = useLanguageContext();
  // const { isMobile } = useMediaBreakpoints();

  // const currentBanner = useMemo(() => {
  //   return APP_IMGS[language][isMobile ? "mobile" : "desktop"].bannerBuyPlan;
  // }, [language, isMobile]);

  // useEffect(() => {
  //   preloadImages([banners]);
  // }, [banners]);

  return (
    <OpenTabLink href={PATHS.supplierPricing}>
      <StyledInviteRegisterBanner {...props}>
        {/* <img src={currentBanner} alt="img" /> */}
        <BuyerBanners positionBanner="header-login"/>
      </StyledInviteRegisterBanner>
    </OpenTabLink>
  );
};

export const TryTrialPlanBanner = ({
  title = "special_promotions_oct_2",
}: {
  title?: string;
}) => {
  const t = useIntl();
  return (
    <StyledTryTrialPlanBanner
      direction="row"
      spacing={2}
      justifyContent="center"
      alignItems="center"
    >
      <XlTextMd color="var(--yellow-500)" className="title">
        {t.formatMessage({ id: title })}
      </XlTextMd>
      <OpenTabLink href={PATHS.freshdiPromotion}>
        <YellowButton>
          {t.formatMessage({ id: "special_promotions_oct_btn" })}
        </YellowButton>
      </OpenTabLink>
    </StyledTryTrialPlanBanner>
  );
};

const StyledInviteRegisterBanner = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  img: {
    aspectRatio: "10.7 / 1",
    width: "100%",
    objectFit: "cover",
  },
  padding: 0,
  [theme.breakpoints.down("md")]: {
    img: {
      aspectRatio: "6 / 1",
    },
  },
}));

const StyledTryTrialPlanBanner = styled(Stack)(({ theme }) => ({
  background: "linear-gradient(90deg, #FFF4E5 0%, #FFFFFF 52%, #FFF4E5 100%)",
  padding: "8px 16px",
  button: {
    width: "auto",
    padding: "3px 14px",
    height: 34,
    fontSize: 16,
    fontWeight: 600,
    whiteSpace: "nowrap",
  },
  [theme.breakpoints.down("md")]: {
    ".title": {
      fontSize: 10,
    },
    button: {
      fontSize: 10,
      padding: "0px 14px",
    },
  },
}));
