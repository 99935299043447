import { Link, Stack, StackProps, styled } from "@mui/material";
import { SwiperSlide, Swiper } from "swiper/react";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import { CSSProperties, useRef, useMemo } from "react";
import { APP_IMGS } from "assets/images";
import { PATHS, SEARCH_PARAM_VALUES, SEARCH_PARAMS } from "constants/routes";
import useMediaBreakpoints from "hooks/useMediaBreakpoints";
import useUser from "hooks/useUser";
import { addUtmParam, objectToQueryString } from "helpers/search";
import { useLanguageContext } from "providers/mul-language";
import { encodeBase64 } from "helpers/base64";

SwiperCore.use([Autoplay, Pagination]);

type propsType = {
  banners?: {
    url: string;
    img: any;
  }[];
  stylesImgCss?: CSSProperties;
  positionBanner?: string;
  isNotRandom?: boolean
}
const BuyerBanners = (props: StackProps & propsType) => {
  const swiperRef = useRef<any>(null);
  const { stylesImgCss, positionBanner, isNotRandom } = props;
  const { isMobile } = useMediaBreakpoints();
  const { isLoggedIn } = useUser();
  const { language } = useLanguageContext();

  const banners = useMemo(() => {
    if (positionBanner === 'header-not-login') {
      const tmpBanners = [
        {
          url: `${addUtmParam(
            PATHS.registerFull +
            "?" +
            objectToQueryString({
              [SEARCH_PARAMS.accountType]: SEARCH_PARAM_VALUES.accountTypeBiz,
              [SEARCH_PARAMS.directUrl]: encodeBase64(window.location.href),
            })
          )}`,
          img: APP_IMGS[language][isMobile ? "mobile" : "desktop"].bannerRegister,
        },
        {
          url: `${PATHS.buyerSuppliersFullForBuyer}/RAT-Rice-Asia-Trade`,
          img: APP_IMGS["common"][isMobile ? "mobile" : "desktop"]["banner_buyer_header1"],
        },
        {
          url: `${PATHS.buyerSuppliersFullForBuyer}/FGE-Fruit-Global-Export`,
          img: APP_IMGS["common"][isMobile ? "mobile" : "desktop"]["banner_buyer_header2"],
        },
        {
          url: `${PATHS.buyerSuppliersFullForBuyer}/EOSAT-Edible-Oil-South-Asia-Trade`,
          img: APP_IMGS["common"][isMobile ? "mobile" : "desktop"]["banner_buyer_header3"],
        },
        {
          url: `${PATHS.buyerSuppliersFullForBuyer}/VGE-Vegetables-Global-Export`,
          img: APP_IMGS["common"][isMobile ? "mobile" : "desktop"]["banner_buyer_header4"],
        },
        {
          url: `${PATHS.buyerSuppliersFullForBuyer}/SGE-Spices-Global-Export`,
          img: APP_IMGS["common"][isMobile ? "mobile" : "desktop"]["banner_buyer_header5"],
        },
        {
          url: `${PATHS.buyerSuppliersFullForBuyer}/CGE-Coffee-Global-Export`,
          img: APP_IMGS["common"][isMobile ? "mobile" : "desktop"]["banner_buyer_header6"],
        },
        {
          url: `${PATHS.buyerSuppliersFullForBuyer}/BGE-Bean-Global-Export`,
          img: APP_IMGS["common"][isMobile ? "mobile" : "desktop"]["banner_buyer_header7"],
        },
        {
          url: `${PATHS.buyerSuppliersFullForBuyer}/MPAT-Meat-Poultry-America-Trade`,
          img: APP_IMGS["common"][isMobile ? "mobile" : "desktop"]["banner_buyer_header8"],
        },
        {
          url: `${PATHS.buyerSuppliersFullForBuyer}/Weihai-Yueting-Trading-Co-Ltd`,
          img: APP_IMGS["common"][isMobile ? "mobile" : "desktop"]["banner_buyer_header9"],
        },
        {
          url: `${PATHS.buyerSuppliersFullForBuyer}/Jimmy-Chan`,
          img: APP_IMGS["common"][isMobile ? "mobile" : "desktop"]["banner_buyer_header10"],
        },
      ];
      return tmpBanners.map((banner, i) => ({
        url: banner.url,
        img: banner.img,
      }));
    }
    else if (positionBanner === 'header-login') {
      const tmpBanners = [
        {
          url: PATHS.supplierPricing,
          img: APP_IMGS[language][isMobile ? "mobile" : "desktop"].bannerBuyPlan,
        },
        {
          url: `${PATHS.buyerSuppliersFullForBuyer}/RAT-Rice-Asia-Trade`,
          img: APP_IMGS["common"][isMobile ? "mobile" : "desktop"]["banner_buyer_header1"],
        },
        {
          url: `${PATHS.buyerSuppliersFullForBuyer}/FGE-Fruit-Global-Export`,
          img: APP_IMGS["common"][isMobile ? "mobile" : "desktop"]["banner_buyer_header2"],
        },
        {
          url: `${PATHS.buyerSuppliersFullForBuyer}/EOSAT-Edible-Oil-South-Asia-Trade`,
          img: APP_IMGS["common"][isMobile ? "mobile" : "desktop"]["banner_buyer_header3"],
        },
        {
          url: `${PATHS.buyerSuppliersFullForBuyer}/VGE-Vegetables-Global-Export`,
          img: APP_IMGS["common"][isMobile ? "mobile" : "desktop"]["banner_buyer_header4"],
        },
        {
          url: `${PATHS.buyerSuppliersFullForBuyer}/SGE-Spices-Global-Export`,
          img: APP_IMGS["common"][isMobile ? "mobile" : "desktop"]["banner_buyer_header5"],
        },
        {
          url: `${PATHS.buyerSuppliersFullForBuyer}/CGE-Coffee-Global-Export`,
          img: APP_IMGS["common"][isMobile ? "mobile" : "desktop"]["banner_buyer_header6"],
        },
        {
          url: `${PATHS.buyerSuppliersFullForBuyer}/BGE-Bean-Global-Export`,
          img: APP_IMGS["common"][isMobile ? "mobile" : "desktop"]["banner_buyer_header7"],
        },
        {
          url: `${PATHS.buyerSuppliersFullForBuyer}/MPAT-Meat-Poultry-America-Trade`,
          img: APP_IMGS["common"][isMobile ? "mobile" : "desktop"]["banner_buyer_header8"],
        },
        {
          url: `${PATHS.buyerSuppliersFullForBuyer}/Weihai-Yueting-Trading-Co-Ltd`,
          img: APP_IMGS["common"][isMobile ? "mobile" : "desktop"]["banner_buyer_header9"],
        },
        {
          url: `${PATHS.buyerSuppliersFullForBuyer}/Jimmy-Chan`,
          img: APP_IMGS["common"][isMobile ? "mobile" : "desktop"]["banner_buyer_header10"],
        },
      ];
      return tmpBanners.map((banner, i) => ({
        url: banner.url,
        img: banner.img,
      }));
    }
    else if (positionBanner === 'right-detail') {
      const tmpBanners = [
        {
          url: `${PATHS.supplierPricing}?tab=half_yearly`,
          img: APP_IMGS["common"][isMobile ? "mobile" : "desktop"]["banner_right_detail"],
        },
      ];
      return tmpBanners.map((banner, i) => ({
        url: banner.url,
        img: banner.img,
      }));
    }
    else {
      const tmpBanners = [
        {
          url: `${PATHS.buyerSuppliersFullForBuyer}/CTai-International`,
          img: APP_IMGS["common"][isMobile ? "mobile" : "desktop"]["banner_buyer1"],
        },
        {
          url: `${PATHS.buyerSuppliersFullForBuyer}/Dcr-Harvest-LLC`,
          img: APP_IMGS["common"][isMobile ? "mobile" : "desktop"]["banner_buyer2"],
        },
        {
          url: `${PATHS.buyerSuppliersFullForBuyer}/TRADE-DOLLYR-GROUP-S-A-S-`,
          img: APP_IMGS["common"][isMobile ? "mobile" : "desktop"]["banner_buyer3"],
        },
        {
          url: `${PATHS.buyerSuppliersFullForBuyer}/Bright-Kporye`,
          img: APP_IMGS["common"][isMobile ? "mobile" : "desktop"]["banner_buyer4"],
        },
        {
          url: `${PATHS.buyerSuppliersFullForBuyer}/Matr-Consulting-Private-Limited-T0aNko`,
          img: APP_IMGS["common"][isMobile ? "mobile" : "desktop"]["banner_buyer5"],
        },
        {
          url: `${PATHS.buyerSuppliersFullForBuyer}/Ak%C4%B1n-Global-Food`,
          img: APP_IMGS["common"][isMobile ? "mobile" : "desktop"]["banner_buyer6"],
        },
        {
          url: `${PATHS.buyerSuppliersFullForBuyer}/PT-Asri-Kopi-Indonesia-OVW0Wn`,
          img: APP_IMGS["common"][isMobile ? "mobile" : "desktop"]["banner_buyer7"],
        },
        {
          url: `${PATHS.buyerSuppliersFullForBuyer}/CASAANDAQUIES`,
          img: APP_IMGS["common"][isMobile ? "mobile" : "desktop"]["banner_buyer8"],
        },
        {
          url: `${PATHS.buyerSuppliersFullForBuyer}/Vision-Transtech-India-vTjCqr`,
          img: APP_IMGS["common"][isMobile ? "mobile" : "desktop"]["banner_buyer9"],
        },
        {
          url: `${PATHS.buyerSuppliersFullForBuyer}/NFF-CO-LTD-`,
          img: APP_IMGS["common"][isMobile ? "mobile" : "desktop"]["banner_buyer10"],
        },
      ];
      return tmpBanners.map((banner, i) => ({
        url: banner.url,
        img: banner.img,
      }));
    }
  }, [isMobile, isLoggedIn]);

  const randomBanner = useMemo(() => {
    const randomIndex = Math.floor(Math.random() * banners.length);
    return banners[randomIndex];
  }, []);

  return (
    <StyledBanners spacing={3} {...props}>
      <div>
        <Swiper
          ref={swiperRef}
          className="banners"
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          // autoplay
          // loop
          pagination={{
            clickable: false,
          }}
          modules={[Pagination]}
        >
          {isNotRandom  && banners.map((banner, i) => (
            <SwiperSlide key={i}>
              <Link href={banner.url} target="_blank">
                <img src={banner.img} alt="banner" style={stylesImgCss} loading="lazy"/>
              </Link>
            </SwiperSlide>
          ))}
          {randomBanner && (
            <Link href={randomBanner.url} target="_blank">
              <img src={randomBanner.img} alt="banner" style={stylesImgCss} loading="lazy"/>
            </Link>
          )}
        </Swiper>
      </div>
    </StyledBanners>
  );
};

const StyledBanners = styled(Stack)(({ theme }) => ({
  ".banners": {
    // height: 465,
    // aspectRatio: "4.75 / 1",
    width: "100%",
    ".swiper-slide": {
      // borderRadius: 16,
      overflow: "hidden",
      width: "100%",
      "& a > span": {
        height: "100%",
        width: "100%",
      },
    },
    ".swiper-pagination": {
      ">span": {
        width: 12,
        height: 12,
      },
      ".swiper-pagination-bullet": {
        backgroundColor: "#fff",
      },
      ".swiper-pagination-bullet-active": {},
    },
    img: {
      // height: "100%",
      display: "block",
      width: "100%",
    },
  },
  ".benefits": {
    a: {
      flex: 1,
    },
    img: {
      width: "100%",
      // borderRadius: 8,
    },
  },

  [theme.breakpoints.down("md")]: {
    ".banners": {
      // height: 360,
      // aspectRatio: "2 / 1",
    },
    ".benefits": {
      img: {
        borderRadius: 0,
      },
    },
  },
}));

export default BuyerBanners;