import { Link, styled } from "@mui/material";
import { APP_ICONS } from "assets/icons";
import { PATHS } from "constants/routes";

export default function FreshdiLogo() {
  return (
    <StyledLink underline="none" href={PATHS.sourcingRequestsFull}>
      <img className="logo" src={APP_ICONS.logo} alt="logo" />
    </StyledLink>
  );
}

const StyledLink = styled(Link)({
  ".logo": {
    height: 48,
  },
  p: {
    fontSize: 8,
    color: "#000",
  },
});
