import { Box, styled } from "@mui/material";
import { COLORS } from "constants/layout";

const StyledBuyer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 8,
  borderRadius: 8,
  padding: 16,
  overflow: "hidden",
  position: "relative",
  backgroundColor: "#fff",
  border: `1px solid ${COLORS.gray[200]}`,
  boxSizing: "border-box",
  height: "100%",
  width: "100%",
  // height: 341,
  ".info-box": {
    width: "60%",
    ".info-text": {
      color: COLORS.gray[500],
      span: {
        color: COLORS.gray[800],
      },
    },
    ".categories-text a": {
      color: COLORS.green[600],
    },
    ".company_img": {
      width: 60,
      height: 60,
      borderRadius: 0,
    },
    ".orange-label": {
      padding: "2px 8px",
    },
    ".medias": {
      gap: 8,
      img: {
        height: 70,
        width: 90,
        objectFit: "cover",
      },
    },
  },
  ".info-box-3": {
    width: "100%",
    ".info-text": {
      color: COLORS.gray[500],
      span: {
        color: COLORS.gray[800],
      },
    },
    ".categories-text a": {
      color: COLORS.green[600],
    },
    ".company_img": {
      width: 60,
      height: 60,
      borderRadius: 0,
    },
    ".orange-label": {
      padding: "2px 8px",
    },
    ".medias": {
      gap: 8,
      img: {
        height: 70,
        width: 90,
        objectFit: "cover",
      },
    },
  },
  ".info-box-2": {
    width: "75%",
    ".info-text": {
      color: COLORS.gray[500],
      span: {
        color: COLORS.gray[800],
      },
    },
    ".categories-text a": {
      color: COLORS.green[600],
    },
    ".company_img": {
      width: 60,
      height: 60,
      borderRadius: 0,
    },
    ".orange-label": {
      padding: "2px 8px",
    },
    ".medias": {
      gap: 8,
      img: {
        height: 70,
        width: 90,
        objectFit: "cover",
      },
    },
  },
  "& .trust_icon": {
    position: "absolute",
    top: 0,
    right: 0,
    width: 40,
  },
  "& .categories": {
    color: "var(--gray)",
    minHeight: 18,
  },
  ".infos-text": {
    color: "var(--gray)",
  },
  ".right-box": {
    width: "40%",
    justifyContent: 'flex-end',
    ".rfq": {
      padding: 8,
      "p, .rfq-title": {
        fontSize: 12,
      },
    },
    ".media": {
      borderRadius: 8,
      overflow: "hidden",
      ".swiper-slide": {
        height: 164,
      },
    },
    ".media, .rfq": {
      flex: 1,
      maxWidth: "calc(50% - 4px)",
    },
  },
  ".right-box-2": {
    width: "25%",
    justifyContent: 'flex-end',
    ".rfq": {
      padding: 8,
      "p, .rfq-title": {
        fontSize: 12,
      },
    },
    ".media": {
      borderRadius: 8,
      overflow: "hidden",
      ".swiper-slide": {
        height: 164,
      },
    },
    ".media, .rfq": {
      flex: 1,
      maxWidth: "100%",
    },
  },
  [theme.breakpoints.down("md")]: {
    // height: 244,
    padding: 8,

    "& .image": {
      // height: 124,
    },
    ".info-box": {
      width: "100%",
      ".company_img": {
        width: 50,
        height: 50,
      },
    },
    ".info-box-2": {
      width: '100%'
    },
    ".info-box-3": {
      width: '100%'
    },
    // gridTemplateColumns: "1fr",
  },
}));

export default StyledBuyer;
