import { PLAN_NAMES, SUPPLIER_PLANS } from "constants/data";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { userSelector } from "services/redux/selectors/userSelector";
import { UserPlanType, UserType } from "types/supplier";

const FREE_PACKAGE = "Free";
const CAN_VIEW_ANALYTIC_PLANS = [
  PLAN_NAMES.trial,
  PLAN_NAMES.pro,
  PLAN_NAMES.starter,
  PLAN_NAMES.advance,
];

const useUser = () => {
  const user: UserType = useSelector(userSelector) || {};
  const { balance } = user;
  const plan = user.plan || {};
  const now = dayjs();

  const currentPlan: UserPlanType = useMemo(() => {
    const isExpired = dayjs(plan.end_date).isBefore(now) || !plan.is_valid;
    return {
      ...plan,
      ...(isExpired
        ? SUPPLIER_PLANS.find((p) => p.label === PLAN_NAMES.free)
        : SUPPLIER_PLANS.find((p) => p.label === plan?.plan_name)),
      plan_name: isExpired ? PLAN_NAMES.free : plan?.plan_name,
      isExpired,
    };
  }, [plan]);

  const isFreePlan = useMemo(
    () =>
      !plan.is_valid ||
      !plan.plan_name ||
      plan.plan_name === FREE_PACKAGE ||
      dayjs(plan.end_date).isBefore(now),
    [plan]
  );

  const canViewAnalytic = useMemo(
    () =>
      CAN_VIEW_ANALYTIC_PLANS.indexOf(currentPlan.label) > -1 && !isFreePlan,
    [currentPlan, isFreePlan]
  );

  return useMemo(
    () => ({
      user,
      userPoint: balance ? Number(balance?.balance || 0) : 0,
      userContactQuote: balance ? Number(balance?.contact_quota || 0) : 0,
      canViewBuyers: plan.plan_name !== FREE_PACKAGE && plan.is_valid,
      isLoggedIn: user.is_logged_in,
      currentPlan,
      canViewAnalytic,
      isFreePlan,
      canShowAi: plan.is_biz_verified ===0 && !plan.is_valid
    }),
    [user, canViewAnalytic, isFreePlan]
  );
};

export default useUser;
