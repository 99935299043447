export const IMG_DOMAIN = process.env.REACT_APP_IMG_DOMAIN;
export const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
export const RESIZE_IMG_DOMAIN = process.env.REACT_APP_RESIZE_IMG_DOMAIN;
export const FILE_DOMAIN = process.env.REACT_APP_FILE_DOMAIN;
export const GG_APP_ID = process.env.REACT_APP_GG_APP_ID;
export const BIZ_DOMAIN = process.env.REACT_APP_BIZ_DOMAIN || "";
export const FRESHDI_HOME_DOMAIN = process.env.REACT_APP_FRESHDI_HOME;
export const GA4_MEASUREMENT_ID = process.env.REACT_APP_GA4_MEASUREMENT_ID;
export const LANDING_DOMAIN = process.env.REACT_APP_LANDING_DOMAIN;
export const SOURCING_DOMAIN = process.env.REACT_APP_SOURCING_DOMAIN;
export const BUYER_CENTER_DOMAIN =
  process.env.REACT_APP_BUYER_CENTER_DOMAIN || "";
export const SUPPLIER_CENTER_DOMAIN =
  process.env.REACT_APP_SUPPLIER_CENTER_DOMAIN || "";

export const DEFAULT_TOKEN = process.env.REACT_APP_TOKEN
  ? `token ${process.env.REACT_APP_TOKEN}`
  : "";
  export const AI_AGENT_API_DOMAIN = process.env.REACT_APP_AI_AGENT_API_DOMAIN;
  export const SOCKET_DOMAIN = process.env.REACT_APP_SOCKET_DOMAIN;